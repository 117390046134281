import { usePaginationState } from '../pagination/usePaginationState'
import type { useFilterState } from '../filter/useFilterState'
import type { EstateType } from '~/types/EstateType'
import type { ApiRequestFetchOptions } from '~/composables/api/useAPIRequest'
import { useAPIRequest } from '~/composables/api/useAPIRequest'
import getEstateApiBaseUrl from '~/utils/getEstateApiBaseUrl'

export async function useEstateListingData(options: {
  estateType: EstateType
  paginationState?: ReturnType<typeof usePaginationState>
  filterState?: ReturnType<typeof useFilterState>
  config?: ApiRequestFetchOptions
  params?: Record<string, any>
  abortController?: AbortController
}) {
  const baseApiUrl = getEstateApiBaseUrl(options.estateType)
  const config = {
    ...(options.config ?? {}),
    params: {
      page: options?.paginationState?.state.currentPage.value,
      limit: options?.paginationState?.state.pageSize,
      ...options?.filterState?.filterValues.value,
      ...(options.params ?? {}),
    },
  }

  return await useAPIRequest(baseApiUrl, config, options?.abortController)
}
